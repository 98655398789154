<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.eodSold') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            <label>{{ $t('sportsbets.startDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-3">
                        <span class="p-input-icon-right">
                            <InputText type="text" :placeholder="$t('search.byCode')" v-model="searchTerm" @input="debounceSearch" />
                            <i class="pi pi-search" />
                        </span>
                    </div>
                    <div class="p-col-6 p-md-5 p-text-right"><Button type="button" :loading="isReportLoading" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                    <div class="p-col-6 p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.savePdf')" :loading="isReportDownloadingPdf" icon="pi pi-download" class="p-button-raised p-button-info" @click="savePdf()" />
                    </div>
                    <div class="p-col-6 p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.saveXls')" :loading="isReportDownloadingXls" icon="pi pi-download" class="p-button-raised p-button-info" @click="saveXls()" />
                    </div>
                    <transition-group name="p-message" tag="div" class="p-col-12">
                        <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ $t('alerts.generalError') }}</Message>
                    </transition-group>
                </div>
                <div class="card" v-if="showReportData">
                    <DataTable @page="onPage($event)" :paginator="true" :rows="perPage" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true" :value="filteredData">
                        <Column>
                            <template #body="slotProps">
                                <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                            </template>
                        </Column>
                        <Column field="ShopCode" :header="$t('decont.shopCode')" :sortable="true"></Column>
                        <Column field="ShopName" :header="$t('sportsSettings.name')" :sortable="true"></Column>

                        <Column field="Sold" :header="$t('reports.sold')" :sortable="true">
                            <template #body="{ data }"> {{ formatCurrency(data.Sold) }} </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../../service/BoReportsApi';
import { BoSaveReportsApi } from '../../../service/BoSaveReportsApi';
import { uiSettings } from '../../../settings/uiSettings';

export default {
    name: 'report',
    data() {
        return {
            reportData: [],
            term: '',
            searchTerm: '',
            BoReportsApi: new BoReportsApi(),
            BoSaveReportsApi: new BoSaveReportsApi(),
            isReportLoading: false,
            isReportDownloadingPdf: false,
            isReportDownloadingXls: false,
            showReportData: false,
            startDateModel: null,
            errorKeyCount: 0,
            showError: false,
            perPage: uiSettings.tableRows,
            crtPage: 1,
        };
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day;
        this.startDateModel = new Date(startDateString);
    },
    computed: {
        filteredData() {
            if (this.notNullOrEmptyField(this.term)) {
                return this.reportData.filter((item) => {
                    return item.ShopCode.toLowerCase().startsWith(this.term.toLowerCase());
                });
            } else {
                return this.reportData;
            }
        },
    },
    methods: {
        showReport() {
            this.showReportData = false;
            this.isReportLoading = true;
            this.BoReportsApi.getEndOfDaySolds(this.formatEnDate(this.startDateModel))
                .then((response) => {
                    this.reportData = response.data;
                    this.isReportLoading = false;
                    this.showReportData = true;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                    this.isReportLoading = false;
                });
        },
        saveXls() {
            this.isReportDownloadingXls = true;
            this.BoSaveReportsApi.saveEndOfDaySolds(this.formatEnDate(this.startDateModel), 1)
                .then((response) => {
                    this.isReportDownloadingXls = false;
                    let blob = new Blob([response.data], { type: 'application/xls' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'DailyBalance_' + this.formatEnDate(this.startDateModel) + '.xlsx';
                    link.click();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                    this.isReportDownloadingXls = false;
                });
        },
        savePdf() {
            this.isReportDownloadingPdf = true;
            this.BoSaveReportsApi.saveEndOfDaySolds(this.formatEnDate(this.startDateModel), 2)
                .then((response) => {
                    this.isReportDownloadingPdf = false;
                    let fileName = 'DailyBalance_' + this.formatEnDate(this.startDateModel) + '.pdf';
                    let blob = new Blob([response.data], { type: 'application/pdf' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.isReportDownloadingPdf = false;
                    this.showError = true;
                    this.errorKeyCount++;
                });
        },
        debounceSearch(event) {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.term = event.target.value;
            }, this.debounceTime);
        },
        onPage(event) {
            this.crtPage = event.page + 1;
        },
        showToast(toastSeverity, message) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: message, life: this.getToastLifeSeconds() });
        },
    },
};
</script>